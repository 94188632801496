<!-- OpcionesTab.vue -->
<template>
    
    <div class="card card-default">
        <div class="card-header">
            <h5>Opciones del módulo</h5>
        </div>
        <!-- Contenido específico para la pestaña Opciones -->
        <div class="card-body">
            <div class="row" style="margin: 0">
                <div class="col-md-6">
                    <div class="row justify-content-between align-items-center">
                        <p>Subir fotos</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="subirfotoscheck">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>Añadir perjudicados</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="anadirperjudicadoscheck">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>URL tevisitamos.net</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="urlcheck">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>Descripción de CIA en el parte</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="descripCIAcheck">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>TeleAsistencia</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="tacheck">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>Pasos guiados</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="pgcheck">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <label for="subd" style="text-decoration: solid;">Subdominio</label>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="text" id="subd" v-model="subdominio" style="height:60% ;width:80%">
                        </div>
                    </div>                 
                </div>
                <div class="col-md-6">
                    <div class="row justify-content-between align-items-center">
                        <p>Enviar comunicaciones</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="enviarcomun">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>Descargar parte</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="descargarparte">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>Reclamaciones</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="reclamaciones">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>Realizar encuesta</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="realizarencuesta">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <p>Acceso con NIF</p>
                        <div class="d-flex justify-content-center" style="width: 40%;">
                            <input type="checkbox" id="subirf" v-model="accesoconNIF">
                        </div>
                    </div>
                    <div class="row justify-content-between align-items-center">
                        <label for="subd">SMS automáico</label>
                        <div style="display:flex; justify-content: space-between;flex-wrap: wrap ;width: 50%;">
                            <div class="row">
                                <p style="margin: 0;">CITA</p>
                                <input v-model="SMSauto" value="1" style="margin-left: 5px;" type="radio">
                            </div>
                            <div class="row">
                                <p style="margin: 0;">TRAMITAR</p>
                                <input v-model="SMSauto" value="2" style="margin-left: 5px;" type="radio">
                            </div>
                            <div class="row">
                                <p style="margin: 0;">NO</p>
                                <input v-model="SMSauto" value="0" style="margin-left: 5px;" type="radio">
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div style="margin-top: 10px; margin-bottom: 10px;">
                <label for="polit">Política de devoluciones</label>
                <textarea style="width: 100%;" rows="7" id="polit" v-model="ta1"></textarea>
            </div>
            <div>
                <div class="row justify-content-between align-items-center" style="margin: 0; ">
                    <label class="col-md-5" for="respauto">Respuesta automática al recibir documentos</label>
                    <div class="col-md-3" style="display:grid; grid-template-columns: repeat(auto-fill, 1fr);grid-auto-flow: column;">
                        <div class="row">
                            <p style="margin: 0;">SMS</p>
                            <input v-model="respAuto" value="1" style="margin-left: 5px;" type="radio">
                        </div>
                        <div class="row">
                            <p style="margin: 0;">EMAIL</p>
                            <input v-model="respAuto" value="2" style="margin-left: 5px;" type="radio">
                        </div>
                        <div class="row">
                            <p style="margin: 0;">NO</p>
                            <input v-model="respAuto" value="0" style="margin-left: 5px;" type="radio">
                        </div>
                    </div>
                </div>
                <div>
                    <textarea style="width: 100%;" rows="7" id="respauto" v-model="ta2"></textarea>
                </div>
            </div>
            <div style="margin-top: 10px;">
                <button class="btn btn-light btn-sm" style="border: 1px solid grey;" @click="visible=true">Palabras claves</button>
                <button class="btn btn-light btn-sm" style="margin-left:15px;border: 1px solid grey;" @click="guardarDatos">Guardar</button>
            </div>
            <steclaves :visible="visible" :claves="claves" @update:visible="cerrarModal"/>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import steclaves from './ste_claves.vue'
export default {
    data() {
        return {
            pgcheck:0,
            subirfotoscheck: 0,
            urlcheck:0,
            anadirperjudicadoscheck: 0,
            descripCIAcheck:0,
            tacheck:0,
            enviarcomun:0,
            descargarparte:0,
            reclamaciones:0,
            realizarencuesta:0,
            accesoconNIF:0,
            subdominio:'',
            subdtext: '',
            ta1: '',
            ta2: '',
            SMSauto: 3,
            respAuto:3,
            visible: false,
            claves:{},
        };
    },
    components: {
        steclaves,
    },
    methods:{
        async guardarDatos(){
            const api = new PwgsApi();
            const res = await api.put('ste', {
                    subir_fotos: this.subirfotoscheck? 1 : 0,
                    add_perjudicado: this.anadirperjudicadoscheck? 1 : 0,
                    url_tevisitamos: this.urlcheck? 1 : 0,
                    descripcion_cia_parte: this.descripCIAcheck? 1 : 0,
                    tele_asistencia: this.tacheck? 1 : 0,
                    realizar_encuesta: this.realizarencuesta? 1 : 0,
                    subdominio: this.subdominio,
                    acceso_con_nif:this.accesoconNIF? 1 : 0,
                    enviar_comunicaciones: this.enviarcomun? 1 : 0,
                    descargar_parte: this.descargarparte? 1 : 0,
                    enviar_reclamaciones: this.reclamaciones? 1 : 0,
                    tipo_respuesta_documentos: this.respAuto,
                    politica_devoluciones: this.ta1,
                    respuesta_documentos: this.ta2,
                    sms_automatico: this.SMSauto,
                    pasos_guiados: this.pgcheck? 1 : 0,
            });
            console.log('respuesta', res);
        },
        cerrarModal(){
            this.visible = false;
        },
        async comprobarDatos(){
            const api = new PwgsApi();
            const res = await api.get('ste');
            const config = res.configuracion;
            this.pgcheck = config.pasos_guiados == 1;
            this.subirfotoscheck = config.subir_fotos == 1;
            this.anadirperjudicadoscheck = config.add_perjudicado == 1;
            this.urlcheck = config.url_tevisitamos == 1;
            this.descripCIAcheck = config.descripcion_cia_parte == 1;
            this.tacheck = config.tele_asistencia == 1;
            this.subdominio = config.subdominio;
            this.enviarcomun = config.enviar_comunicaciones == 1;
            this.descargarparte = config.descargar_parte == 1;
            this.reclamaciones = config.enviar_reclamaciones == 1;
            this.realizarencuesta = config.realizar_encuesta == 1;
            this.accesoconNIF = config.acceso_con_nif == 1;
            this.SMSauto = config.sms_automatico;
            this.respAuto = config.tipo_respuesta_documentos;
            this.claves = res.palabrasclave.palabras_clave;
            this.ta1 = config.politica_devoluciones;
            this.ta2 = config.respuesta_documentos;

        },
    },
    mounted() {
        this.comprobarDatos();
    },
};
</script>

<template>
    <Dialog modal header="Palabras clave" :style="{width: '800px'}">  
        <p v-for="clave in Object.entries(claves)" :key="clave">{{ clave [0]}}</p>
    </Dialog>
</template>
<script>
import Dialog from 'primevue/dialog';
export default ({
    props: ['claves'],
    components:{
        Dialog,
    },
    data(){
        return{
          
        }
    },        
    methods:{
        
    },
    watch:{
        
    }
});
</script>
<style scoped>

</style>